let Selectors = {
  group: `.menu__group`,
  groupToggleInput: `.menu__group-toggle-input`,
};

let toggles = document.querySelectorAll(Selectors.groupToggleInput);
let groups = document.querySelectorAll(Selectors.group);

groups.forEach((group, groupIndex) => {
  group.addEventListener('click', event => {
    event.stopPropagation();

    toggles.forEach((toggle, toggleIndex) => {
      if (groupIndex === toggleIndex) return;

      toggle.checked = false;
    })
  });
});

document.body.addEventListener('click', () => toggles.forEach(x => x.checked = false));
